import {translate} from "./translate";
import {useEffect, useState} from "react";
import Project from '../constants/project';
import {isUserFrom} from "../util/get-user-country";

export const useI18n = () => {
    // Determine initial language by project name
    const initialLanguage = process.env.REACT_APP_PROJECT === Project.ANONRUBOT ? 'ru' : 'en';
    const [language, setLanguage] = useState(initialLanguage);
    useEffect(() => {
        determineUserLanguage().then(setLanguage);
    }, []);
    return {
        // bind language to translate function
        translate: translate.bind(null, language),
        language,
    };
}

const determineUserLanguage = async () => {
    const isChatBot = process.env.REACT_APP_PROJECT === Project.CHATBOT;
    // If user is from Indonesia and use chatBot then return "id" language
    if (isChatBot && (await isUserFrom('ID'))) {
        return 'id'
    }
    return isChatBot ? 'en' : 'ru';
}