import React from 'react';
import './Button.css';
import ClipLoader from "react-spinners/ClipLoader";

export const Button = ({onClick, title, isDisabled = false, isFetching = false}) => <div
    className={`Button ${(isDisabled || isFetching) && 'disabled'}`}
    onClick={onClick}
>
    {isFetching && <ClipLoader
        size={20}
        color={'#B5B5B5'}
        cssOverride={{marginRight: 5}}
    />}
    <span
        className={`Button-title ${(isDisabled || isFetching) && 'disabled'}`}
    >
        {title}
    </span>
</div>
