import {useEffect, useState} from "react";
import {AccountValidationState} from "../constants/accountValidationStates";
import {validateAccount} from "./validate-account";

export const useValidationState = (userId) => {
    const [accountValidationState, setAccountValidationState] = useState(AccountValidationState.NONE);
    useEffect(() => {
        if (userId.length > 5) {
            setAccountValidationState(AccountValidationState.VALIDATING);
            validateAccount(userId)
                .then((res) => {
                    if (res) {
                        setAccountValidationState(AccountValidationState.CORRECT_ID);
                    } else {
                        setAccountValidationState(AccountValidationState.WRONG_ID);
                    }
                })
                .catch(() => setAccountValidationState(AccountValidationState.WRONG_ID));
        }
    }, [userId]);

    return [accountValidationState, setAccountValidationState];
}
