import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useDarkMode() {
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get("darkTheme")) {
            document.body.classList.add("dark");
        } else {
            document.body.classList.remove("dark");
        }
    }, [location.search]);
}

export default useDarkMode;