import "./App.scss";
import {useEffect, useState} from "react";
import {anonrubotPremiums} from "../constants/premiums/anonrubot-premiums";
import {paymentMethodsMap} from "../constants/paymentMethods";
import {validateAccount} from "../util/validate-account";
import {getApiUrl} from "../util/get-api-url";
import {
    AccountValidationState,
} from "../constants/accountValidationStates";
import {PERSIST_USER_ID_KEY} from "../constants/commonConstants";
import {Button} from "../components/Button";
import {useSearchParams} from "react-router-dom";
import {useValidationState} from "../util/use-validation-state";
import {Section, SelectableBox, Subtitle, Title} from "../components/ui-kit";
import UserIdInput from "../components/UserIdInput";
import {useI18n} from "../i18n/use-i18n";
import Head from "../components/Head";
import {getBotName} from "../util/get-bot-name";
import {getBotUrl} from "../util/get-bot-url";

function AnonRuBot() {
    const [userId, setUserId] = useState('');
    const [searchParams] = useSearchParams();
    const [activePremium, setActivePremium] = useState('month');
    const paymentMethods = paymentMethodsMap['ru'];
    const [method, setMethod] = useState(paymentMethods[0].identifier);
    const [emptyUserIdError, setEmptyUserIdError] = useState(false);
    const [validationState, setAccountValidationState] = useValidationState(userId);
    const [isFetching, setIsFetching] = useState(false);
    const {translate} = useI18n();

    const validateAccountAndUpdateState = (userId) => {
        validateAccount(userId)
            .then((res) => {
                if (res) {
                    setAccountValidationState(AccountValidationState.CORRECT_ID);
                } else {
                    setAccountValidationState(AccountValidationState.WRONG_ID);
                }
            })
            .catch(() => setAccountValidationState(AccountValidationState.WRONG_ID));
    }
    useEffect(() => {
        const searchParamsUserId = searchParams.get('userId');
        const localStorageUserId = localStorage.getItem(PERSIST_USER_ID_KEY);
        const persistedUserId = searchParamsUserId || localStorageUserId;
        if (persistedUserId) {
            // Restore persisted uid on mount
            setUserId(persistedUserId);
            validateAccountAndUpdateState(persistedUserId);
        }
        const paramPremium = searchParams.get('premium');
        if (paramPremium && anonrubotPremiums.find((p) => p.identifier === paramPremium)) {
            setActivePremium(paramPremium);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function onFormSubmit(event) {
        event.preventDefault();

        if (AccountValidationState.CORRECT_ID !== validationState) {
            return;
        }

        if (userId.length === 0) {
            setEmptyUserIdError(true);
            return;
        }
        // If all checks were passed then persist uid
        localStorage.setItem(PERSIST_USER_ID_KEY, userId);
        setIsFetching(true);
        const premium = anonrubotPremiums.find((p) => p.identifier === activePremium);
        const urlParams = new URLSearchParams({
            userId,
            method,
            user_lang: 'ru',
            premium: premium.identifier,
        });
        fetch(getApiUrl() + `?${urlParams.toString()}`)
            .then(async (response) => {
                if (response.status === 200) {
                    const {url} = await response.json();
                    window.location.href = url;
                } else {
                    alert(`При попытке создать ссылку на оплату что-то пошло не так: ${response.statusText} (${await response.text()})`);
                }
            })
            .catch(() => {
                alert('Что-то пошло не так');
            })
            .finally(() => setIsFetching(false));
    }

    return (
        <div className={'App-container'}>
            <Head/>
            <form onSubmit={onFormSubmit}>
                <h1 className={'App-header'}>
                    {translate("premium-in")} {getBotName()}
                </h1>
                <Section>
                    <Title>
                        {translate("enter-user-id")}
                    </Title>
                    <Subtitle>
                        {translate("get-user-id-hint-p1")} <a href={getBotUrl()} target="_blank" rel="noreferrer" className="App-link-blue">{getBotName()}</a>
                    </Subtitle>
                    <UserIdInput
                        validationState={validationState}
                        setAccountValidationState={setAccountValidationState}
                        setUserId={setUserId}
                        emptyUserIdError={emptyUserIdError}
                        validateAccountAndUpdateState={validateAccountAndUpdateState}
                        userId={userId}
                    />
                    {emptyUserIdError && AccountValidationState.CORRECT_ID !== validationState &&
                        <label className={'App-input-invalid-label'}>{translate("enter-user-id")}</label>}
                </Section>
                <Section>
                    <Title>
                        {translate("choose-plan")}
                    </Title>
                    {anonrubotPremiums.map((item) => {
                        const {identifier, fancyName} = item;
                        const isChecked = identifier === activePremium;
                        return <SelectableBox
                            key={identifier}
                            identifier={identifier}
                            onClick={setActivePremium}
                            isChecked={isChecked}
                            title={fancyName}
                            subtitle={item.getFancyPrice()}
                        />;
                    })}
                </Section>
                <Section>
                    <Title>
                        {translate("choose-payment-method")}
                    </Title>
                    {paymentMethods.map(({identifier, title, extra}) => {
                        const isChecked = identifier === method;
                        return <SelectableBox
                            key={identifier}
                            identifier={identifier}
                            onClick={setMethod}
                            isChecked={isChecked}
                            title={translate(title)}
                            subtitle={extra}
                        />;
                    })}
                    <Button
                        onClick={onFormSubmit}
                        title={translate("go-to-payment")}
                        isDisabled={AccountValidationState.CORRECT_ID !== validationState}
                        isFetching={isFetching}
                    />
                    {validationState === AccountValidationState.WRONG_ID && <span className={'App-wrong-user-id-error'}>
                        {translate("user-id-does-not-exist")}
            </span>}
                </Section>
            </form>
        </div>
    );
}

export default AnonRuBot;
