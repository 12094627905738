import ru from './translations/ru.json';
import en from './translations/en.json';
import id from './translations/id.json';

const DEFAULT_LANGUAGE = 'en';

const translationsMap = {
    ru,
    en,
    id,
};

export const translate = (language, stringKey, params = []) => {
    const translations = translationsMap[language] ?? translationsMap[DEFAULT_LANGUAGE];
    const translatedString = translations[stringKey] ?? translationsMap[DEFAULT_LANGUAGE][stringKey];

    // If translated string does not exist then return key itself
    if (!translatedString) {
        return stringKey;
    }

    return insertParams(translatedString, params);
};

const insertParams = (translatedString, params = []) => {
    // If no params were provided then return original string
    if (!params.length) {
        return translatedString;
    }

    let parsedString = translatedString;

    params.forEach((v, index) => {
        parsedString = parsedString.split(`{${index}}`).join(v);
    });

    return parsedString;
};
