import {Helmet} from "react-helmet";
import {useI18n} from "../i18n/use-i18n";

const Head = () => {
    const {translate} = useI18n();
    return <Helmet>
        <title>{translate("page-head-title")}</title>
        <meta content={translate("page-head-meta-title")} property="og:title"/>
        <meta content={translate("page-head-description")}
              property="og:description"/>
        <meta content={translate("page-head-description")}
              property="description"/>
    </Helmet>;
}

export default Head;