import Project from "../constants/project";

export function getBotUrl() {
  switch (process.env.REACT_APP_PROJECT) {
    case Project.CHATBOT:
      return "https://t.me/chatbot";
    case Project.ANONRUBOT:
      return "https://t.me/anonrubot";
    default:
      console.error("Unknown project", process.env.REACT_APP_PROJECT);
      return "https://t.me/anonrubot";
  }
}
