export const AccountValidationState = {
    NONE: 'none',
    VALIDATING: 'validating',
    WRONG_ID: 'wrongId',
    CORRECT_ID: 'correctId',
};

export const mapValidationStateToCSS = {
    [AccountValidationState.NONE]: '',
    [AccountValidationState.VALIDATING]: 'validating',
    [AccountValidationState.WRONG_ID]: 'wrong',
    [AccountValidationState.CORRECT_ID]: 'correct',
}


export const mapValidationStateToTitle = {
    [AccountValidationState.NONE]: '',
    [AccountValidationState.VALIDATING]: "validating-user-id",
    [AccountValidationState.WRONG_ID]: "wrong-user-id",
    [AccountValidationState.CORRECT_ID]: "correct-user-id",
}
