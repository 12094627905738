import {
    AccountValidationState,
    mapValidationStateToCSS,
    mapValidationStateToTitle
} from "../constants/accountValidationStates";
import {PERSIST_USER_ID_KEY} from "../constants/commonConstants";
import {useI18n} from "../i18n/use-i18n";

const UserIdInput = ({
                         validationState,
                         setAccountValidationState,
                         setUserId,
                         emptyUserIdError,
                         validateAccountAndUpdateState,
                         userId,
                     }) => {
    const {translate} = useI18n();
    return <div className={'App-input-user-id-container'}>

        {/* Disable password manager. See: https://stackoverflow.com/a/41946532 and https://stackoverflow.com/a/23016234 */}
        <input id="fake_user_name" name="fake_user[name]" style={{position: 'absolute', top: '-10000px'}} type="text" value="Safari Autofill Me"/>
        <input name="disable-pwd-mgr-1" type="password" id="disable-pwd-mgr-1" style={{display: 'none'}} value="disable-pwd-mgr-1" />
        <input name="disable-pwd-mgr-2" type="password" id="disable-pwd-mgr-2" style={{display: 'none'}} value="disable-pwd-mgr-2" />
        <input name="disable-pwd-mgr-3" type="password" id="disable-pwd-mgr-3" style={{display: 'none'}} value="disable-pwd-mgr-3" />

        <input
            className={
                `App-input-user-id
                                ${emptyUserIdError && AccountValidationState.CORRECT_ID !== validationState && 'invalid'} 
                                ${AccountValidationState.NONE !== validationState && 'extra-padding'}`
            }
            type="text"
            name="useridsearch" // use "search" to prevent autofill, see: https://stackoverflow.com/a/68260636
            autoComplete="off"
            placeholder={'544407461'}
            value={userId}
            onChange={(e) => {
                setAccountValidationState(AccountValidationState.NONE);
                setUserId(e.target.value?.trim());
                // If user removes input's value then remove persisted uid
                if (e.target.value?.trim() === "") {
                    localStorage.removeItem(PERSIST_USER_ID_KEY);
                }
            }}
            onFocus={(event) => {
                // Wrapping select() in setTimeout to make it work in Safari
                // https://stackoverflow.com/questions/54229359/why-does-select-not-work-on-safari-with-reactjs
                setTimeout(() => event.target.select(), 0);
            }}
            onBlur={() => {
                if (validationState !== AccountValidationState.NONE) {
                    // If validation state already exists, no need to validate again
                    return;
                }

                if (userId.length !== 0) {
                    setAccountValidationState(AccountValidationState.VALIDATING);
                    validateAccountAndUpdateState(userId);
                } else {
                    setAccountValidationState(AccountValidationState.NONE);
                }
            }}
        />
        {validationState !== AccountValidationState.NONE &&
            <span
                className={`App-account-validating-state ${mapValidationStateToCSS[validationState]}`}>
                {translate(mapValidationStateToTitle[validationState])}
                        </span>}
    </div>
}

export default UserIdInput;
